<template>
  <div>
    <app-card>
      <template #body>
        <reception-form :block-id="blockId" @change="form = $event"/>
      </template>
    </app-card>

    <portal to="subheader-toolbar">
      <b-button class="mr-3" variant="transparent-white" @click="$router.push({name: 'showBlock', params: {id: blockId}, hash: '#receptions'})">
        {{ $t('btn.back_to_block') }}
      </b-button>
      <b-button variant="success" @click="create">
        {{ $t('btn.create') }}
      </b-button>
    </portal>
  </div>
</template>

<script>
import AppCard from '@/components/AppCard';
import ReceptionForm from './components/ReceptionForm'

export default {
  components: {
    AppCard,
    ReceptionForm
  },
  data: () => ({
    blockId: null,
    form: null
  }),
  beforeMount() {
    this.blockId = parseInt(this.$route.query.block_id);
  },
  methods: {
    create() {
      this.$store.dispatch('receptionsStore/CREATE', this.form).then(() => {
        this.$router.push({name: 'showBlock', params: {id: this.blockId}, hash: '#receptions'});
      });
    }
  }
}
</script>